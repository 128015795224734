import * as React from "react";

import Seo from "../components/Seo";
import Layout from "../components/Layout";
import * as styles from "./index.module.css";

const About = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="About Sophia" />
      <div className={styles.outerContainer}>
        <div className={styles.aboutContainer}>
          <section>
            <div>
              <div className={styles.aboutImageContainer}>
              </div>
              <div>
                <p>
                  Hi, I’m Sophia! I'm currently in the process of learning all about soap making and candle making.
                </p>
              </div>
              <div>
                Images
              </div>
            </div>
          </section>

        </div>
      </div>
    </Layout>
  );
};

export default About;